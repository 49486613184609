import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/outline';

const TableStatusCell = (props) => {
    const {
        cell: { value, render },
        cell,
    } = props;

    if (value === 'active' || value === true) {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-green-100 text-green-800">
                Active
            </span>
        );
    }

    if (value === 'inactive' || value === false) {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-red-100 text-red-800">Inactive</span>
        );
    }

    if (value === 'paid') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-green-100 text-green-800">Paid</span>
        );
    }

    if (value === 'paused') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-yellow-100 text-yellow-800">
                Paused
            </span>
        );
    }

    if (value === 'fulfilled') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-green-100 text-green-800">
                Fulfilled
            </span>
        );
    }

    if (value === 'pending') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-yellow-100 text-yellow-800">
                Pending
            </span>
        );
    }

    if (value === 'cancelled') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-red-100 text-red-800">Cancelled</span>
        );
    }

    if (value === 'failed') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-red-100 text-red-800">Failed</span>
        );
    }

    if (value === 'breakfast') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-blue-100 text-blue-800">
                Breakfast
            </span>
        );
    }

    if (value === 'snack') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-yellow-100 text-yellow-800">
                Snack
            </span>
        );
    }

    if (value === 'meal') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-purple-100 text-purple-800">
                Meal
            </span>
        );
    }

    if (value === 'main') {
        return (
            <span className="px-3 inline-flex text-xs leading-5 font-medium rounded-full bg-purple-100 text-purple-800">
                Main
            </span>
        );
    }

    if (value !== undefined && typeof value === 'string' && value.charAt(0) === '/') {
        return (
            <Link to={value} className="text-orange font-medium">
                <ArrowRightIcon className="text-orange w-5 h-5" />
            </Link>
        );
    }

    return render('Cell');
};

export default TableStatusCell;
