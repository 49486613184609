/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTable, useFilters, usePagination } from 'react-table';
import { SearchIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import TableCellParser from './TableCellParser';

import CustomerSearch from './Search/CustomerSearch';
import OrderSearch from './Search/OrderSearch';
import IngredientSearch from './Search/IngredientSearch';
import MealFilters from './Search/MealFilters';

const Table = (props) => {
    const { columns, data, name } = props;
    const [filterInput, setFilterInput] = useState('');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setFilter,
        pageCount,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        usePagination
    );

    const handleSearchByName = (e) => {
        const value = e.target.value || undefined;
        setFilter('name', value);
        setFilterInput(value);
    };

    const handleSearchByPhone = (e) => {
        const value = e.target.value || undefined;
        setFilter('phone', value);
        setFilterInput(value);
    };

    const handleSearchByPaid = (e) => {
        const value = e.target.value || undefined;
        setFilter('paymentStatus', value);
        setFilterInput(value);
    };

    const handleSearchByShipped = (e) => {
        const value = e.target.value || undefined;
        setFilter('fulfillmentStatus', value);
        setFilterInput(value);
    };

    const handleFilterByType = (value) => {
        setFilter('type', value);
        setFilterInput(value);
    };

    const handleFilterByStatus = (value) => {
        setFilter('status', value);
        setFilterInput(value);
    };

    return (
        <div className="flex flex-col ">
            {name === 'Customers' || name === 'Subscriptions' ? (
                <CustomerSearch handleSearchByName={handleSearchByName} handleSearchByPhone={handleSearchByPhone} name={name} />
            ) : (
                ''
            )}
            {name === 'Ingredients' ? <IngredientSearch handleSearchByName={handleSearchByName} name={name} /> : ''}
            {name === 'Meals' ? (
                <MealFilters
                    handleSearchByName={handleSearchByName}
                    handleFilterByType={handleFilterByType}
                    handleFilterByStatus={handleFilterByStatus}
                    name={name}
                />
            ) : (
                ''
            )}
            {name === 'Orders' ? (
                <OrderSearch
                    handleSearchByName={handleSearchByName}
                    handleSearchByPhone={handleSearchByPhone}
                    handleSearchByPaid={handleSearchByPaid}
                    handleSearchByShipped={handleSearchByShipped}
                    name={name}
                />
            ) : (
                ''
            )}
            <div className="bg-white rounded-lg border ">
                <div className="px-10 py-8  flex justify-between items-center">
                    <h1 className="text-xl font-semibold text-gray-800">{name} List</h1>
                    <div className="flex-1 flex items-center justify-end">
                        <p className="text-gray-700 pr-3 text-xs">
                            <span className="font-medium text-sm">{pageIndex + 1}</span> /{' '}
                            <span className="font-medium text-sm">{pageCount}</span>
                        </p>
                        <button
                            type="button"
                            disabled={!canPreviousPage}
                            onClick={() => previousPage()}
                            className="mr-3 relative inline-flex items-center px-3 py-2 text-sm font-regular rounded-md bg-gray-50"
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                        </button>
                        <button
                            type="button"
                            disabled={!canNextPage}
                            onClick={() => nextPage()}
                            className="relative inline-flex items-center px-3 py-2 ml-1 text-sm font-regular rounded-md bg-gray-50"
                        >
                            <ChevronRightIcon className="h-4 w-4" />
                        </button>
                    </div>
                </div>

                <div className="grid gap-6 pb-1">
                    <table className="min-w-full " {...getTableProps()}>
                        <thead className="bg-white bg-gray-50 border-gray-100 border-b border-t">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="pb-2">
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="px-5 py-2 uppercase text-left font-semibold text-gray-600 text-xs"
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className="bg-white" {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => (
                                            <td
                                                className="px-4 py-4 whitespace-nowrap font-medium text-sm text-gray-700 "
                                                {...cell.getCellProps()}
                                            >
                                                <TableCellParser cell={cell} />
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table;
