import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';

import Table from '../../components/Core/Table/Table';

function Customers(props) {
    const [getUserData, setUserData] = useState('');
    const [tableData, setTableData] = useState('');

    const fetchSubscriptionData = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/ingredients/read`).then((response) => setUserData(response.data));
    };

    const populateTableData = () => {
        const data = [];

        for (let i = 0; i < getUserData.length; i += 1) {
            const { name, price, proteins, fats, carbs, calories, _id } = getUserData[i];

            const row = {
                col1: name,
                col2: `€${price}`,
                col3: calories,
                col4: carbs,
                col5: proteins,
                col6: fats,
                col7: `/admin/ingredients/${_id}`,
            };
            data.push(row);
        }
        setTableData(data);
    };

    useEffect(() => {
        fetchSubscriptionData();
    }, []);

    useEffect(() => {
        populateTableData();
    }, [getUserData]);

    // Table column names
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'col1',
            },
            {
                Header: 'Price',
                accessor: 'col2',
            },
            {
                Header: 'Calories',
                accessor: 'col3',
            },
            {
                Header: 'Carbs',
                accessor: 'col4',
            },
            {
                Header: 'Proteins',
                accessor: 'col5',
            },
            {
                Header: 'Fats',
                accessor: 'col6',
            },
            {
                Header: '',
                accessor: 'col7',
            },
        ],
        []
    );

    return (
        <div className="w-full min-h-screen bg-gray-100 ">
            <div className=" w-full h-28 flex-none flex bg-white border-b">
                <div className="flex items-center p-8 px-12 justify-between w-full max-w-6xl mx-auto">
                    <h1 className="text-3xl font-semibold">Browse Ingredients</h1>
                    <div className="flex items-center">
                        <Link to="/admin/ingredients/add" className="button-primary-md flex justify-center items-center">
                            <PlusIcon className="h-4 w-4 -ml-2 mr-3" /> Create Ingredient
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-12 max-w-6xl mx-auto">
                {tableData.length > 0 ? (
                    <Table columns={columns} data={tableData} name="Ingredients" />
                ) : (
                    <div className="grid gap-8">
                        <div className="animate-pulse bg-gray-200 w-full h-24 rounded-lg "></div>
                        <div className="animate-pulse bg-gray-200 w-full h-screen rounded-lg"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRouter(Customers);
