/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { IKContext, IKImage, IKUpload } from 'imagekitio-react';
import { useAuth0 } from '@auth0/auth0-react';

import { TrashIcon } from '@heroicons/react/outline';

import TextInput from '../../components/Form/TextInput';
import TestSelect from '../../components/Form/TestSelect';
import FormSection from '../../components/Form/FormSection';
import Meal from '../../components/Core/Web/Meal';
import CustomSelect from '../../components/Form/MultiSelect.tsx';

function EditMeal(props) {
    const { getAccessTokenSilently } = useAuth0();

    const {
        match: {
            params: { id },
        },
        company,
    } = props;

    const [meal, setMeal] = useState(false);
    const [ingredients, setIngredients] = useState(false);
    const [sides, setSides] = useState(false);

    const action = id === 'add' ? 'Add' : 'Edit';

    const fetchMeal = () => {
        if (action === 'Edit') {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/meals/read`, {
                    query: { _id: id },
                })
                .then((response) => setMeal(response.data[0]));
        }
    };

    const fetchIngredients = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/ingredients/read`).then((response) => {
            const ingredients = response.data;
            const ingredientsRenamed = ingredients.map(({ _id, name }) => ({
                value: _id,
                label: name,
            }));

            setIngredients(ingredientsRenamed);
        });
    };

    const fetchSides = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/sides/read`).then((response) => {
            const sides = response.data;
            console.log('🚀 ~ file: EditMeals.js ~ line 60 ~ axios.post ~ sides', sides);
            const sidesRenamed = sides.map(({ _id, name }) => ({
                value: _id,
                label: name,
            }));

            setSides(sidesRenamed);
        });
    };

    const urlEndpoint = 'https://ik.imagekit.io/mealify';
    const publicKey = 'public_hgn+h6HmS/leVVfcf6aoWRqHnnc=';
    const authenticationEndpoint = process.env.REACT_APP_API_URL + '/auth';

    const saveMeal = async (values) => {
        const token = await getAccessTokenSilently();

        if (action === 'Edit') {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/meals/update`,
                    {
                        query: { _id: id },
                        body: values,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(() => toast.success('Item Saved'));
        } else {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/meals/create`, values, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((result) => {
                    window.location.href = `/admin/meals/${result.data._id}`;
                });
        }
    };

    const onError = (err) => {
        console.log('Error', err);
    };

    const onSuccess = (res, index, arrayHelpers) => {
        arrayHelpers.replace(index, `${res.name}`);
    };

    useEffect(() => {
        fetchMeal();
        fetchIngredients();
        fetchSides();
    }, []);

    if (typeof company !== 'object') {
        return (
            <div className="p-8 w-full h-screen grid grid-cols-3 gap-8">
                <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg col-span-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg"></div>
            </div>
        );
    }

    console.log(meal, 'meal');

    return (
        <div className="min-h-screen w-full">
            <Toaster position="bottom-center" />

            <Formik
                enableReinitialize
                initialValues={{
                    active: meal ? meal.active : true,
                    name: meal ? meal.name : '',
                    images: meal ? meal.images : [],
                    type: meal ? meal.type : '',
                    allergens: meal ? meal.allergens : [],
                    description: meal ? meal.description : '',
                    shortDescription: meal ? meal.shortDescription : '',
                    macros: {
                        calories: meal ? meal.macros.calories : '',
                        carbs: meal ? meal.macros.carbs : '',
                        proteins: meal ? meal.macros.proteins : '',
                        fats: meal ? meal.macros.fats : '',
                        price: meal ? meal.macros.price : '',
                    },
                    ingredients: meal.ingredients ? meal.ingredients : [],
                    sides: meal.sides ? meal.sides : [],
                    imageTag: meal ? meal.imageTag : '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string().max(40, 'Must be 40 characters or less').required('Meal name is required'),
                })}
                onSubmit={(values) => {
                    console.log(values);
                    saveMeal(values);
                }}
            >
                {({ values }) => (
                    <Form className="flex flex-col w-full bg-gray-100">
                        {/* Header */}
                        <div className=" w-full h-28 flex-none flex bg-white border-b">
                            <div className="flex items-center p-8 px-12 justify-between w-full  max-w-6xl mx-auto">
                                <h1 className="text-3xl font-semibold">Edit Meal</h1>
                                <div className="flex items-center">
                                    <Link to="/admin/meals" className="px-8 py-3 text-gray-800 font-semibold">
                                        Back to Meals
                                    </Link>
                                    <button type="submit" id="save" className="button-primary-md">
                                        Save Meal
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Body */}
                        <div className="flex flex-row main-area p-12 flex-1 max-w-6xl mx-auto">
                            <div className="h-full grid grid-cols-3 gap-12">
                                <div className="col-span-2 grid gap-12">
                                    <FormSection title="Information">
                                        <TextInput label="Name" name="name" type="text" />

                                        <TextInput
                                            label="Short Description"
                                            name="shortDescription"
                                            type="text"
                                            placeholder="Max 30 characters"
                                        />
                                        <div className=" col-span-2">
                                            <TextInput label="Long Description" name="description" type="text" />
                                        </div>
                                        <div className="grid grid-cols-4 gap-6 col-span-2">
                                            <TextInput label="Calories" name="macros.calories" type="text" />
                                            <TextInput label="Carbs" name="macros.carbs" type="text" />

                                            <TextInput label="Protein" name="macros.proteins" type="text" />
                                            <TextInput label="Fats" name="macros.fats" type="text" />
                                        </div>
                                        <div className="col-span-2">
                                            <p className="block text-sm font-medium text-gray-700 mb-1">Allergens</p>

                                            <Field
                                                className=""
                                                name="allergens"
                                                options={company.generator.allergen.options}
                                                component={CustomSelect}
                                                placeholder="Select any allergens"
                                                isMulti
                                            />
                                        </div>
                                    </FormSection>

                                    <FormSection title="Images">
                                        <div className="col-span-2">
                                            <IKContext
                                                publicKey="public_hgn+h6HmS/leVVfcf6aoWRqHnnc="
                                                urlEndpoint="https://ik.imagekit.io/mealify"
                                                authenticationEndpoint={authenticationEndpoint}
                                                transformationPosition="path"
                                            >
                                                <FieldArray
                                                    name="images"
                                                    render={(arrayHelpers) => (
                                                        <div className="grid grid-cols-3 gap-6">
                                                            {values.images.map((image, index) => (
                                                                <div key={index}>
                                                                    {image !== '' && image !== undefined ? (
                                                                        <div className="h-40 w-40 rounded-md relative ">
                                                                            <IKImage
                                                                                path={`/${image}`}
                                                                                className="rounded-md overflow-hidden"
                                                                                transformation={[
                                                                                    {
                                                                                        height: '180px',
                                                                                        width: '180px',
                                                                                        focus: 'auto',
                                                                                    },
                                                                                ]}
                                                                                height="180px"
                                                                                width="180px"
                                                                            />
                                                                            <div className="absolute bottom-0 right-0 p-3 pb-1">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    <TrashIcon className="h-6 w-6 text-white bg-black rounded-full p-1" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="">
                                                                            <label
                                                                                htmlFor={`${index}-upload`}
                                                                                className="text-orange font-medium flex items-center h-40 w-40 border-dashed border justify-center rounded-md relative"
                                                                            >
                                                                                Choose Image
                                                                                <div className="absolute bottom-0 right-0 p-3 pb-1">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                                    >
                                                                                        <TrashIcon className="h-6 w-6 text-orange rounded-full p-1" />
                                                                                    </button>
                                                                                </div>
                                                                            </label>

                                                                            <IKUpload
                                                                                id={`${index}-upload`}
                                                                                className="hidden"
                                                                                onError={onError}
                                                                                onSuccess={(res) =>
                                                                                    onSuccess(res, index, arrayHelpers)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            <button
                                                                className="text-orange font-medium flex items-center h-40 w-40 border-dashed border justify-center rounded-md"
                                                                type="button"
                                                                onClick={() => {
                                                                    arrayHelpers.push('');
                                                                    const selector = values.images.length + '-upload';
                                                                    setTimeout(() => {
                                                                        const uploadButton = document.getElementById(selector);
                                                                        uploadButton.click();
                                                                    }, 600);
                                                                }}
                                                            >
                                                                Add Image
                                                            </button>
                                                        </div>
                                                    )}
                                                />
                                            </IKContext>
                                        </div>
                                    </FormSection>
                                    <FormSection title="Ingredients">
                                        <div className="col-span-2">
                                            <FieldArray
                                                name="ingredients"
                                                render={(arrayHelpers) => (
                                                    <div className="grid gap-6">
                                                        {values.ingredients.map((ingredient, index) => (
                                                            <div key={index}>
                                                                {/** both these conventions do the same */}
                                                                <div className="grid grid-cols-6 gap-6">
                                                                    <div className="col-span-3">
                                                                        <Field
                                                                            component={TestSelect}
                                                                            name={`ingredients.${index}.ingredient`}
                                                                            label="Name"
                                                                            options={ingredients}
                                                                            value={values.ingredients[index].ingredient}
                                                                        />
                                                                    </div>

                                                                    <div className="col-span-2">
                                                                        <TextInput
                                                                            label="Quantity"
                                                                            name={`ingredients.${index}.quantity`}
                                                                            type="number"
                                                                            postfix="g"
                                                                            placeholder="12"
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        className="text-red-600 mt-6 border rounded-md w-10 "
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5 mx-auto " />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button
                                                            className="text-orange font-semibold flex items-center"
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.push({
                                                                    ingredient: '',
                                                                    quantity: '',
                                                                })
                                                            }
                                                        >
                                                            + Add Ingredient
                                                        </button>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </FormSection>
                                    <FormSection title="Sides">
                                        <div className="col-span-2">
                                            <FieldArray
                                                name="sides"
                                                render={(arrayHelpers) => (
                                                    <div className="grid gap-6">
                                                        {values.sides.map((side, index) => (
                                                            <div key={index}>
                                                                {/** both these conventions do the same */}
                                                                <div className="grid grid-cols-6 gap-6">
                                                                    <div className="col-span-3">
                                                                        <Field
                                                                            component={TestSelect}
                                                                            name={`sides.${index}`}
                                                                            label="Name"
                                                                            options={sides}
                                                                            value={values.sides[index]}
                                                                        />
                                                                    </div>

                                                                    <button
                                                                        className="text-red-600 mt-6 border rounded-md w-10 "
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5 mx-auto " />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button
                                                            className="text-orange font-semibold flex items-center"
                                                            type="button"
                                                            onClick={() => arrayHelpers.push('')}
                                                        >
                                                            + Add Side
                                                        </button>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </FormSection>
                                </div>
                                {/* Sidebar */}
                                <div className="">
                                    <div className="sticky top-8 grid gap-12">
                                        <Meal values={values} company={company} />
                                        <FormSection title="Overview">
                                            <div className="col-span-2 grid gap-6">
                                                <Field
                                                    component={TestSelect}
                                                    name="active"
                                                    label="Status"
                                                    options={[
                                                        {
                                                            value: true,
                                                            label: 'Active',
                                                        },
                                                        {
                                                            value: false,
                                                            label: 'Inactive',
                                                        },
                                                    ]}
                                                    value={values.active}
                                                />
                                                <Field
                                                    component={TestSelect}
                                                    className="col-span-2"
                                                    name="type"
                                                    label="Type"
                                                    options={[
                                                        {
                                                            value: 'breakfast',
                                                            label: 'Breakfast',
                                                        },
                                                        {
                                                            value: 'meal',
                                                            label: 'Meal',
                                                        },
                                                        {
                                                            value: 'main',
                                                            label: 'Main',
                                                        },
                                                        {
                                                            value: 'snack',
                                                            label: 'Snack',
                                                        },
                                                    ]}
                                                    value={values.type}
                                                />
                                                <Field
                                                    component={TestSelect}
                                                    name="imageTag"
                                                    label="Image Tag"
                                                    options={company.options.tags}
                                                    value={values.imageTag}
                                                />
                                            </div>
                                        </FormSection>
                                        <button type="submit" className="button-primary-md w-full">
                                            Save Meal
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withRouter(EditMeal);
