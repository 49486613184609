import React from 'react';
import { Text, View } from '@react-pdf/renderer';

function PacklistHeader(props) {
    console.log('🚀 ~ file: Header.js ~ line 5 ~ PacklistHeader ~ props', props);

    const {
        plan: {
            config: { breakfast, meal, snack, daysOfFood },
        },
        order: { name, stripeCustomer },
        styles,
    } = props;
    return (
        <View style={styles.headerLayout}>
            <View style={styles.headerSection}>
                <Text style={styles.heading}>Quantities</Text>
                <br />
                <Text style={styles.basicContent}>{breakfast * daysOfFood} Breakfasts</Text>
                <br />
                <Text style={styles.basicContent}>{meal * daysOfFood} Meals</Text>
                <br />
                <Text style={styles.basicContent}>{snack * daysOfFood} Snacks</Text>
                <br />
            </View>
            <View style={styles.headerSection}>
                <Text style={styles.heading}>Customer</Text>
                <br />
                <Text style={styles.basicContent}>{name}</Text>
                <br />
                <Text style={styles.basicContent}>{stripeCustomer}</Text>
                <br />
            </View>
        </View>
    );
}

export default PacklistHeader;
