import React from 'react';

function Popup(props) {
    const { children, title } = props;

    return (
        <div className="bg-white rounded-lg border ">
            <div className="px-10 py-8 border-b">
                <h1 className="text-xl font-semibold text-gray-800">{title}</h1>
            </div>

            <div className="grid grid-cols-2 gap-6 px-10 py-10 pb-14">{children}</div>
        </div>
    );
}

export default Popup;
