import React from 'react';
import { IKContext, IKImage } from 'imagekitio-react';

function Meal(props) {
    const {
        values: {
            macros: { calories, fats, proteins, carbs },
            imageTag,
            images,
            name,
        },
        company: {
            options: { tags: imageTags },
        },
    } = props;

    const findTagColor = () => {
        return imageTags.find((item) => item.value == imageTag);
    };

    const openQuickView = () => {
        const idString = 'quick-view-button-' + _id;
        const button = document.getElementById(idString);
        button.click();
    };

    return (
        <div className="grid grid-cols-9 bg-white rounded-md border h-32">
            {/* image of the meal */}
            <div className="relative col-span-3">
                <IKContext
                    publicKey="public_hgn+h6HmS/leVVfcf6aoWRqHnnc="
                    urlEndpoint="https://ik.imagekit.io/mealify"
                    transformationPosition="path"
                >
                    {imageTag !== undefined && imageTag !== '' ? (
                        <p
                            className={
                                'text-sm  font-semibold text-white absolute top-0 left-0 mt-2 rounded-r py-0.5 px-2 -ml-0.5 ' +
                                findTagColor().color
                            }
                        >
                            {imageTag}
                        </p>
                    ) : (
                        ''
                    )}

                    <IKImage
                        path={'/' + images[0]}
                        className="rounded-l-md imageStretch overflow-hidden"
                        transformation={[
                            {
                                height: '180px',
                                focus: 'auto',
                            },
                        ]}
                        height="180px"
                    />
                </IKContext>
            </div>

            {/* description of the meal */}
            <div className="col-span-5 p-5">
                <h6 className="text-gray-800 font-semibold mt-0 h-10 overflow-hidden text-sm">{name}</h6>
                <p className="text-xs text-gray-600 mt-4 font-light flex">
                    <div className="flex flex-col mr-2">
                        <span className="text-gray-600 font-light text-xs">kCal</span>
                        <span className="text-black font-medium text-center">{calories}</span>
                    </div>
                    <div className="flex flex-col mr-2">
                        <span className="text-gray-600 font-light text-xs">Carbs</span>
                        <span className="text-black font-medium text-center">{carbs}</span>
                    </div>
                    <div className="flex flex-col mr-2">
                        <span className="text-gray-600 font-light text-xs">Fats</span>
                        <span className="text-black font-medium text-center">{fats}</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="text-gray-600 font-light text-xs">Proteins</span>
                        <span className="text-black font-medium text-center">{proteins}</span>
                    </div>
                </p>
            </div>

            {/* secondary buttons of the meal */}
            <div className="flex flex-col justify-between items-center relative"></div>
        </div>
    );
}

export default Meal;
