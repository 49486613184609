import React, { useEffect } from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';

import Header from './Header';
import Footer from './Footer';

import TypeRows from './TypeRows';

// Create Document Component
function Packlist(props) {
    console.log('🚀 ~ file: Packlist.js ~ line 12 ~ Packlist ~ props', props);
    const { orders, plans, part } = props;

    const styles = StyleSheet.create({
        page: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
        },
        counterDiv: {
            padding: '10px',
            backgroundColor: 'red',
        },
        heading: {
            fontSize: '20px',
            fontWeight: 'bold',
            paddingBottom: '10px',
        },
        basicContent: {
            fontSize: '16px',
            paddingBottom: '4px',
            color: '#4c4c4c',
        },
        headerLayout: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            border: '1px solid #f5f5f5',
        },
        footerLayout: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            border: '1px solid #f5f5f5',
        },
        headerSection: {
            width: '100%',
            padding: '25px',
        },
        tableData: {
            padding: '7px 10px',
        },
        tableRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            borderBottom: '2px solid black',
        },
        mealCell: {
            padding: '7px 10px',
            width: '100%',
        },
        checkboxCell: {
            padding: '10px 10px',
            border: '2px solid #000',
        },
    });

    useEffect(() => {
        GenerateAllPages(orders, plans);
    }, []);

    const GenerateAllPages = () => {
        const pages = orders.map((order, index) => generateOnePage(order, plans[index]));
        return pages;
    };

    const generateOnePage = (order, plan) => (
        <Page size="A4" style={styles.page}>
            <Header order={order} plan={plan} styles={styles} />
            <TypeRows plan={plan} part={part} styles={styles} type="breakfast" />
            <TypeRows plan={plan} part={part} styles={styles} type="meal" />
            <TypeRows plan={plan} part={part} styles={styles} type="snack" />
            <Footer styles={styles} />
        </Page>
    );

    return (
        <Document>
            <GenerateAllPages />
        </Document>
    );
}

export default Packlist;
