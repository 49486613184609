import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PDFViewer } from '@react-pdf/renderer';
import { useAuth0 } from '@auth0/auth0-react';

import Packlist from '../components/Exports/Packlist/Packlist.js';
import { deliveryExport, getActiveOrders } from '../utilities/DeliveryExport.js';
import FormSection from '../components/Form/FormSection';

function Exports(props) {
    const [orderData, setOrderData] = useState([]);
    const [planData, setPlanData] = useState([]);
    const [part, setPart] = useState(0);

    const { getAccessTokenSilently } = useAuth0();

    const packlistExportPart1 = async () => {
        setPart(1);
        packlistExport();
    };

    const packlistExportPart2 = async () => {
        setPart(2);
        packlistExport();
    };

    const weeksMealsExport = async () => {
        const token = await getAccessTokenSilently();

        // Get all the active orders
        const orders = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/orders/read`,
            {
                query: { fulfillmentStatus: 'pending' },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        // Pull the plans from those
        const planIdArray = createPlanIdArray(orders.data);
        const plansData = await fetchPlanDataParralel(planIdArray);

        // Feed it back into the states
    };

    const packlistExport = async () => {
        const token = await getAccessTokenSilently();

        // Get all the active orders
        const orders = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/orders/read`,
            {
                query: { fulfillmentStatus: 'pending' },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        // Pull the plans from those
        const planIdArray = createPlanIdArray(orders.data);
        const plansData = await fetchPlanDataParralel(planIdArray);

        // Feed it back into the states
        setOrderData(orders.data);
        setPlanData(plansData);
    };

    const createPlanIdArray = (orders) => {
        const array = [];
        orders.map((order) => {
            array.push(order.plan);
        });
        return array;
    };

    const fetchSinglePlan = async (id) => {
        const token = await getAccessTokenSilently();

        const singlePlan = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/plans/read-populated`,
            {
                query: { _id: id },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return singlePlan.data;
    };

    const fetchPlanDataParralel = async (arrayOfPlanIds) => {
        const promises = [];
        let result = [];

        let index = 0;

        while (index < arrayOfPlanIds.length) {
            promises.push(fetchSinglePlan(arrayOfPlanIds[index]));
            index++;
        }

        const data = await Promise.all(promises);
        data.forEach(({ data }) => {
            result = [...result, data];
        });

        return data;
    };

    return (
        <div className="w-full min-h-screen bg-gray-100 ">
            <div className=" w-full h-28 flex-none flex bg-white border-b">
                <div className="flex items-center p-8 px-12 justify-between w-full max-w-6xl mx-auto">
                    <h1 className="text-3xl font-semibold">Exports</h1>
                    <div className="flex items-center"></div>
                </div>
            </div>

            <div className="p-12 max-w-6xl mx-auto grid grid-cols-2 gap-8">
                <FormSection title="Export Delivery CSV">
                    <p className="col-span-2">CSV export of all active subscriptions.</p>
                    <button onClick={deliveryExport} type="button" className="button-primary-md">
                        Export
                    </button>
                </FormSection>

                <FormSection title="Export Packlists">
                    <p className="col-span-2">PDF export of customer packlists.</p>
                    <button onClick={packlistExportPart1} type="button" className="button-primary-md">
                        Export 1
                    </button>
                    <button onClick={packlistExportPart2} type="button" className="button-primary-md">
                        Export 2
                    </button>
                </FormSection>

                <FormSection title="Export Weeks Meals">
                    <p className="col-span-2">To calculate ingredients.</p>
                    <button onClick={weeksMealsExport} type="button" className="button-primary-md">
                        Export
                    </button>
                </FormSection>

                <div className="bg-white sm:rounded-lg col-span-2">
                    {orderData.length > 0 && planData.length > 0 ? (
                        <PDFViewer className="w-full h-screen mt-10">
                            <Packlist orders={orderData} plans={planData} part={part} className="w-full h-screen" />
                        </PDFViewer>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Exports;
