/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { TrashIcon } from '@heroicons/react/outline';

import TextInput from '../../components/Form/TextInput';
import TestSelect from '../../components/Form/TestSelect';
import FormSection from '../../components/Form/FormSection';
import CustomSelect from '../../components/Form/MultiSelect.tsx';

function EditSide(props) {
    const { getAccessTokenSilently } = useAuth0();

    const {
        match: {
            params: { id },
        },
        company,
    } = props;

    const [side, setSide] = useState(false);
    const [ingredients, setIngredients] = useState(false);

    const action = id === 'add' ? 'Add' : 'Edit';

    const fetchSide = () => {
        if (action === 'Edit') {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/sides/read`, {
                    query: { _id: id },
                })
                .then((response) => setSide(response.data[0]));
        }
    };

    const fetchIngredients = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/ingredients/read`).then((response) => {
            const ingredients = response.data;
            const ingredientsRenamed = ingredients.map(({ _id, name }) => ({
                value: _id,
                label: name,
            }));

            setIngredients(ingredientsRenamed);
        });
    };

    const urlEndpoint = 'https://ik.imagekit.io/mealify';
    const publicKey = 'public_hgn+h6HmS/leVVfcf6aoWRqHnnc=';
    const authenticationEndpoint = process.env.REACT_APP_API_URL + '/auth';

    const saveSide = async (values) => {
        const token = await getAccessTokenSilently();

        if (action === 'Edit') {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/sides/update`,
                    {
                        query: { _id: id },
                        body: values,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(() => toast.success('Item Saved'));
        } else {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/sides/create`, values, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((result) => {
                    window.location.href = `/admin/sides/${result.data._id}`;
                });
        }
    };

    const onError = (err) => {
        console.log('Error', err);
    };

    const onSuccess = (res, index, arrayHelpers) => {
        arrayHelpers.replace(index, `${res.name}`);
    };

    useEffect(() => {
        fetchSide();
        fetchIngredients();
    }, []);

    if (typeof company !== 'object') {
        return (
            <div className="p-8 w-full h-screen grid grid-cols-3 gap-8">
                <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg col-span-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen w-full">
            <Toaster position="bottom-center" />

            <Formik
                enableReinitialize
                initialValues={{
                    active: side ? side.active : true,
                    name: side ? side.name : '',
                    images: side ? side.images : [],
                    type: side ? side.type : '',
                    allergens: side ? side.allergens : [],
                    description: side ? side.description : '',
                    shortDescription: side ? side.shortDescription : '',
                    macros: {
                        calories: side ? side.macros.calories : '',
                        carbs: side ? side.macros.carbs : '',
                        proteins: side ? side.macros.proteins : '',
                        fats: side ? side.macros.fats : '',
                        price: side ? side.macros.price : '',
                    },
                    ingredients: side.ingredients ? side.ingredients : [],
                    imageTag: side ? side.imageTag : '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string().max(40, 'Must be 40 characters or less').required('Side name is required'),
                })}
                onSubmit={(values) => {
                    console.log(values);
                    saveSide(values);
                }}
            >
                {({ values }) => (
                    <Form className="flex flex-col w-full bg-gray-100">
                        {/* Header */}
                        <div className=" w-full h-28 flex-none flex bg-white border-b">
                            <div className="flex items-center p-8 px-12 justify-between w-full  max-w-6xl mx-auto">
                                <h1 className="text-3xl font-semibold">Edit Side</h1>
                                <div className="flex items-center">
                                    <Link to="/admin/sides" className="px-8 py-3 text-gray-800 font-semibold">
                                        Back to Sides
                                    </Link>
                                    <button type="submit" id="save" className="button-primary-md">
                                        Save Side
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Body */}
                        <div className="flex flex-row main-area p-12 flex-1 max-w-6xl mx-auto">
                            <div className="h-full grid grid-cols-3 gap-12">
                                <div className="col-span-2 grid gap-12">
                                    <FormSection title="Information">
                                        <TextInput label="Name" name="name" type="text" />

                                        <div className="grid grid-cols-4 gap-6 col-span-2">
                                            <TextInput label="Calories" name="macros.calories" type="text" />
                                            <TextInput label="Carbs" name="macros.carbs" type="text" />

                                            <TextInput label="Protein" name="macros.proteins" type="text" />
                                            <TextInput label="Fats" name="macros.fats" type="text" />
                                        </div>
                                        <div className="col-span-2">
                                            <p className="block text-sm font-medium text-gray-700 mb-1">Allergens</p>

                                            <Field
                                                className=""
                                                name="allergens"
                                                options={company.generator.allergen.options}
                                                component={CustomSelect}
                                                placeholder="Select any allergens"
                                                isMulti
                                            />
                                        </div>
                                    </FormSection>

                                    <FormSection title="Ingredients">
                                        <div className="col-span-2">
                                            <FieldArray
                                                name="ingredients"
                                                render={(arrayHelpers) => (
                                                    <div className="grid gap-6">
                                                        {values.ingredients.map((ingredient, index) => (
                                                            <div key={index}>
                                                                {/** both these conventions do the same */}
                                                                <div className="grid grid-cols-6 gap-6">
                                                                    <div className="col-span-3">
                                                                        <Field
                                                                            component={TestSelect}
                                                                            name={`ingredients.${index}.ingredient`}
                                                                            label="Name"
                                                                            options={ingredients}
                                                                            value={values.ingredients[index].ingredient}
                                                                        />
                                                                    </div>

                                                                    <div className="col-span-2">
                                                                        <TextInput
                                                                            label="Quantity"
                                                                            name={`ingredients.${index}.quantity`}
                                                                            type="number"
                                                                            postfix="g"
                                                                            placeholder="12"
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        className="text-red-600 mt-6 border rounded-md w-10 "
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5 mx-auto " />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button
                                                            className="text-orange font-semibold flex items-center"
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.push({
                                                                    ingredient: '',
                                                                    quantity: '',
                                                                })
                                                            }
                                                        >
                                                            + Add Ingredient
                                                        </button>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </FormSection>
                                </div>
                                {/* Sidebar */}
                                <div className="">
                                    <div className="sticky top-8 grid gap-12">
                                        <button type="submit" className="button-primary-md w-full">
                                            Save Side
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default withRouter(EditSide);
