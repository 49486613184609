import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ClipboardIcon, RefreshIcon, ChartPieIcon } from '@heroicons/react/outline';

function Dashboard(props) {
    useEffect(() => {}, []);

    return (
        <div className="wrapper flex flex-col h-screen w-screen relative max-h-screen max-w-screen overflow-hidden">
            <div className=" w-full h-28 flex-none flex  bg-white">
                <div className="flex items-center p-8 px-12 justify-between w-full">
                    <h1 className="text-3xl font-semibold">Edit Meal</h1>
                    <div className="flex items-center">
                        <div className="bg-white px-8 py-3 text-gray-800   font-semibold rounded-md">
                            Back to Meals
                        </div>
                        <div className="bg-orange px-8 py-3 text-white font-semibold rounded-md">
                            Save Meal
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-row flex-1">
                <div className="main-area p-12 flex-1 bg-gray-50 border-t">
                    <div className="h-full">
                        <div className="grid grid-cols-3 gap-12 max-w-6xl">
                            <div className=" h-full col-span-2 grid gap-12 ">
                                <div className="border rounded-md bg-white">
                                    <div className="h-24 px-12 border-b w-full flex justify-between items-center">
                                        <h1 className="text-2xl font-semibold">Information</h1>
                                    </div>
                                    <div className="p-12 grid grid-cols-2 gap-6">
                                        <div className="flex flex-col">
                                            <p className="text-gray-600 mb-2">Title</p>
                                            <div className="h-12 bg-gray-50 border rounded-sm w-full"></div>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-gray-600 mb-2">Label</p>
                                            <div className="h-12 bg-gray-50 border rounded-sm w-full"></div>
                                        </div>
                                        <div className="flex flex-col col-span-2">
                                            <p className="text-gray-600 mb-2">Description</p>
                                            <div className=" h-28 bg-gray-50 border rounded-sm w-full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-md bg-white">
                                    <div className="h-24 px-12 border-b w-full flex justify-between items-center">
                                        <h1 className="text-2xl font-semibold">Images</h1>
                                    </div>
                                    <div className="flex flex-row p-12">
                                        <div className="h-40 w-40 border bg-gray-50 mr-6 rounded-sm"></div>
                                        <div className="h-40 w-40 border bg-gray-50 mr-6 rounded-sm"></div>
                                    </div>
                                </div>
                            </div>

                            <div className=" grid gap-6 py-6 pt-0 auto-rows-min">
                                <div className="h-28 w-full border rounded-md bg-white">
                                    <div className="h-28 w-28 bg-gray-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
