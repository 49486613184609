import axios from 'axios';

export const fetchUser = async (jsonFilter) => {
    const user = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/read`, {
        query: jsonFilter,
    });
    return user;
};

export const fetchCompany = async () => {
    const company = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies/`);
    return company;
};

export const fetchMeals = async () => {
    const meals = await axios.post(`${process.env.REACT_APP_API_URL}/api/meals/read`);
    return meals;
};

export const fetchPlan = async (id) => {
    const plan = await axios.post(`${process.env.REACT_APP_API_URL}/api/plans/read`, {
        query: {
            _id: id,
        },
        limit: 1,
    });
    return plan;
};

export const fetchPopulatedPlan = async (id) => {
    const plan = await axios.post(`${process.env.REACT_APP_API_URL}/api/plans/read-populated`, {
        query: {
            _id: id,
        },
    });
    return plan;
};
