import React from 'react';
import { Formik, Form, useField } from 'formik';

const TextInput = ({ label, ...props }) => {
    // Default input classes
    let extraClasses =
        'focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md';

    extraClasses += props.prefix ? ' pl-7 ' : ' pl-3 ';
    extraClasses += props.postfix ? ' pr-14 ' : ' pr-3 ';

    const [field, meta] = useField(props);
    return (
        <>
            <div>
                <label
                    htmlFor={props.id || props.name}
                    className="block text-sm font-medium text-gray-700"
                >
                    {label}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">{props.prefix}</span>
                    </div>

                    <input
                        type={props.type}
                        value={props.value}
                        name={props.id || props.name}
                        id="price"
                        className={extraClasses}
                        {...field}
                        {...props}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">{props.postfix}</span>
                    </div>
                </div>
                {meta.touched && meta.error ? (
                    <div className="text-red-400 text-sm pl-1 pt-2">{meta.error}</div>
                ) : null}
            </div>
        </>
    );
};

export default TextInput;
