import React from 'react';

const ThreeStats = (props) => {
    const { stats } = props;

    return (
        <dl className="grid grid-cols-1 gap-8 sm:grid-cols-3 mb-8">
            {stats.map((item) => (
                <div key={item.name} className="px-4 py-5 bg-white rounded-lg overflow-hidden sm:p-6 border">
                    <dt className="text-sm font-medium text-brown-dark truncate">{item.name}</dt>
                    <dd className="mt-1 text-3xl font-semibold text-brown-dark-dark">{item.stat}</dd>
                </div>
            ))}
        </dl>
    );
};

export default ThreeStats;
