import axios from 'axios';

const dayjs = require('dayjs');
const fileDownload = require('js-file-download');

export const deliveryExport = async () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/admin/delivery-export`).then((response) => {
        fileDownload(response.data, 'delivery-export.csv');
    });
};

export const packlistExport = async () => {
    getOrders().then((result) => console.log(result, 'result '));
};
