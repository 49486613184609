import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '../../components/Core/Table/Table';
import { withRouter, Link } from 'react-router-dom';

function Customers(props) {
    const [getUserData, setUserData] = useState('');
    const [tableData, setTableData] = useState('');

    const fetchUserData = async () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/read`, {
                jsonFilter: { 'subscription.status': { $ne: '' } },
            })
            .then((response) => setUserData(response.data));
    };

    const populateTableData = () => {
        const data = [];

        for (let i = 0; i < getUserData.length; i += 1) {
            const {
                contactInformation: { fullName, phone },
                subscription: { status },
                _id,
            } = getUserData[i];

            const row = {
                name: fullName,
                phone: phone,
                status: status,
                edit: `/admin/customers/${_id}`,
            };
            data.push(row);
        }
        setTableData(data);
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        populateTableData();
    }, [getUserData]);

    // Table column names
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: '',
                accessor: 'edit',
                className: 'link',
            },
        ],
        []
    );

    return (
        <div className="w-full min-h-screen bg-gray-100 ">
            <div className=" w-full h-28 flex-none flex bg-white border-b">
                <div className="flex items-center p-8 px-12 justify-between w-full max-w-6xl mx-auto">
                    <h1 className="text-3xl font-semibold">Browse Customers</h1>
                    <div className="flex items-center"></div>
                </div>
            </div>
            <div className="p-12 max-w-6xl mx-auto">
                {tableData.length > 0 ? (
                    <Table columns={columns} data={tableData} name="Customers" />
                ) : (
                    <div className="grid gap-8">
                        <div className="animate-pulse bg-gray-200 w-full h-24 rounded-lg "></div>
                        <div className="animate-pulse bg-gray-200 w-full h-screen rounded-lg"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRouter(Customers);
