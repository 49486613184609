/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';

import DropdownFilter from '../../../Form/DropdownFilter';

const IngredientSearch = (props) => {
    const { name, handleSearchByName, handleFilterByType, handleFilterByStatus } = props;

    const [filterType, setFilterType] = useState('');
    const [filterStatus, setFilterStatus] = useState('');

    return (
        <div className="bg-white rounded-lg border mb-8">
            <div className="px-10 py-8 flex justify-between items-center">
                <h1 className="text-xl font-semibold text-gray-800">Filter {name}</h1>
                <DropdownFilter
                    filter={handleFilterByType}
                    className="select w-40"
                    options={[
                        {
                            value: 'breakfast',
                            label: 'Breakfast',
                        },
                        {
                            value: 'meal',
                            label: 'Meal',
                        },
                        {
                            value: 'snack',
                            label: 'Snack',
                        },
                        {
                            value: '',
                            label: 'Choose Type',
                        },
                    ]}
                    value={filterType}
                    setValue={setFilterType}
                />
                <DropdownFilter
                    filter={handleFilterByStatus}
                    className="select w-48"
                    options={[
                        {
                            value: true,
                            label: 'Active',
                        },
                        {
                            value: false,
                            label: 'Inactive',
                        },
                        {
                            value: '',
                            label: 'Choose Status',
                        },
                    ]}
                    value={filterStatus}
                    setValue={setFilterStatus}
                />
                <div className="w-48">
                    <input
                        type="text"
                        name="company-website"
                        onChange={handleSearchByName}
                        id="company-website"
                        className="block flex-0 text-dark-brown px-3 py-2 sm:text-sm rounded-md border-gray-300"
                        placeholder="Search by name"
                    />
                </div>
            </div>
        </div>
    );
};

export default IngredientSearch;
