import { useField } from 'formik';
import Select from 'react-select';

export default function SelectField(props) {
    const { value, setValue, label, filter } = props;

    const onChange = (value) => {
        setValue(value.value);
        filter(value.value);
    };

    return (
        <div className="">
            <p className="block text-sm font-medium text-gray-700 mb-1">{label}</p>
            <Select {...props} value={props.options.filter((option) => option.value === value)} onChange={onChange} />
        </div>
    );
}
