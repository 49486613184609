import { useField } from 'formik';
import Select from 'react-select';

export default function SelectField(props) {
    const {
        value,
        field: { name },
        label,
    } = props;

    const [field, state, { setValue, setTouched }] = useField(name);

    const onChange = (value) => {
        setValue(value.value);
    };

    return (
        <div className="">
            <p className="block text-sm font-medium text-gray-700 mb-1">{label}</p>
            <Select
                {...props}
                value={props.options.filter((option) => option.value === value)}
                onChange={onChange}
                onBlur={setTouched}
            />
        </div>
    );
}
