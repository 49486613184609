import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import TextInput from '../../components/Form/TextInput';
import CustomSelect from '../../components/Form/MultiSelect.tsx';
import FormSection from '../../components/Form/FormSection';

function IngredientForm(props) {
    const {
        match: {
            params: { id },
        },
        name,
        company,
    } = props;

    const [ingredient, setIngredient] = useState(false);

    const action = id === 'add' ? 'Add' : 'Edit';

    const fetchIngredient = () => {
        if (action === 'Edit') {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/ingredients/read`, {
                    query: { _id: id },
                })
                .then((response) => setIngredient(response.data[0]));
        }
    };

    const saveIngredient = (values) => {
        if (action === 'Edit') {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/ingredients/update`, {
                    query: { _id: id },
                    body: values,
                })
                .then(() => toast.success('Item Saved'));
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/api/ingredients/create`, values).then((result) => {
                window.location.href = `/admin/ingredients/${result.data._id}`;
            });
        }
    };

    useEffect(() => {
        fetchIngredient();
    }, []);

    if (typeof company !== 'object') {
        return (
            <div className="p-8 w-full h-screen grid grid-cols-3 gap-8">
                <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg col-span-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg"></div>
            </div>
        );
    }

    return (
        <div className="bg-gray-100 min-h-screen w-full">
            <Toaster position="bottom-center" />

            <Formik
                enableReinitialize
                initialValues={{
                    name: ingredient ? ingredient.name : '',
                    price: ingredient ? ingredient.price : '',
                    calories: ingredient ? ingredient.calories : '',
                    proteins: ingredient ? ingredient.proteins : '',
                    carbs: ingredient ? ingredient.carbs : '',
                    fats: ingredient ? ingredient.fats : '',
                    allergens: ingredient ? ingredient.allergens : [],
                }}
                validationSchema={Yup.object({
                    name: Yup.string().max(30, 'Must be 15 characters or less').required('Ingredient name is required'),
                    price: Yup.number().max(100, "Ingredient price shouldn't be over 100").required('Price is required'),
                    calories: Yup.number().max(1000, "Calories can't be over 1000").required('Calories are required'),
                    proteins: Yup.number().max(100, "Protien can't be over 100").required('Protein is required'),
                    fats: Yup.number().max(100, "Fats can't be over 100").required('Fat is required'),
                    carbs: Yup.number().max(100, "Carbs can't be over 100").required('Carb is required'),
                })}
                onSubmit={(values) => {
                    saveIngredient(values);
                }}
            >
                <Form className="flex flex-col w-full bg-gray-100">
                    {/* Header */}
                    <div className=" w-full h-28 flex-none flex bg-white border-b">
                        <div className="flex items-center p-8 px-12 justify-between w-full">
                            <h1 className="text-3xl font-semibold">Edit Ingredients</h1>
                            <div className="flex items-center">
                                <Link to="/admin/Ingredients" className="px-8 py-3 text-gray-800 font-semibold">
                                    Back to Ingredients
                                </Link>
                                <button type="submit" id="save" className="button-primary-md">
                                    Save Ingredient
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Body */}
                    <div className="flex flex-row main-area p-12 flex-1 max-w-6xl mx-auto">
                        <div className="h-full grid grid-cols-3 gap-12">
                            <div className="col-span-2 grid gap-12">
                                <FormSection title="Information">
                                    <TextInput label="Name" name="name" type="text" />

                                    <TextInput
                                        label="Price"
                                        name="price"
                                        type="number"
                                        prefix="€"
                                        postfix="/ 100g"
                                        placeholder="0.00"
                                    />

                                    <div className="grid col-span-2 grid-cols-4 gap-6 pb-12">
                                        <TextInput
                                            label="Calories"
                                            name="calories"
                                            type="number"
                                            placeholder="240"
                                            prefix="c"
                                            postfix="/ 100g"
                                        />

                                        <TextInput
                                            label="Carb"
                                            name="carbs"
                                            type="number"
                                            placeholder="34.9"
                                            prefix="g"
                                            postfix="/ 100g"
                                        />

                                        <TextInput
                                            label="Protein"
                                            name="proteins"
                                            type="number"
                                            placeholder="10.9"
                                            prefix="g"
                                            postfix="/ 100g"
                                        />

                                        <TextInput
                                            label="Fat"
                                            name="fats"
                                            type="number"
                                            placeholder="11"
                                            prefix="g"
                                            postfix="/ 100g"
                                        />

                                        <div className="col-span-2">
                                            <p className="block text-sm font-medium text-gray-700 mb-1">Allergens</p>

                                            <Field
                                                className=""
                                                name="allergens"
                                                options={company.configurator.filters}
                                                component={CustomSelect}
                                                placeholder="Select any allergens"
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                </FormSection>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default withRouter(IngredientForm);
