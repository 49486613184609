import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRoute from '../../auth/protected-route';

import Exports from '../../screens/Exports';
import Orders from '../../screens/Orders';
import Dashboard from '../../screens/Dashboard';

import Subscriptions from '../../screens/Subscriptions/ViewSubscriptions';
import EditSubscription from '../../screens/Subscriptions/EditSubscription';

import Customers from '../../screens/Customers/ViewCustomers';
import EditCustomer from '../../screens/Customers/EditCustomer';

import Ingredients from '../../screens/Ingredients/ViewIngredients';
import EditIngredient from '../../screens/Ingredients/EditIngredient';

import Meals from '../../screens/Meals/ViewMeals';
import EditMeals from '../../screens/Meals/EditMeals';

import Sides from '../../screens/Sides/ViewSides';
import EditSides from '../../screens/Sides/EditSides';

import './App.css';
import Menu from '../Core/Menu/Menu';

import { fetchCompany, fetchUser, fetchPopulatedPlan } from '../../utilities/ApiUtilities.js';

function App(props) {
    const [company, setCompany] = useState('');
    const companyState = { get: company, set: setCompany, read: fetchCompany };

    useEffect(async () => {
        await companyState.read().then((response) => companyState.set(response.data));
    }, []);

    useEffect(() => {}, []);

    const { isLoading } = useAuth0();

    if (isLoading) {
        return <div></div>;
    }

    return (
        <BrowserRouter>
            <div className="flex max-w-screen">
                <Menu />
                <Switch>
                    <ProtectedRoute exact path="/" component={Dashboard} />

                    <ProtectedRoute path="/admin/dashboard" component={Dashboard} />

                    {/* Ingredients */}
                    <ProtectedRoute
                        path="/admin/ingredients/:id"
                        component={() => <EditIngredient company={companyState.get} />}
                    />

                    <ProtectedRoute path="/admin/ingredients/" component={Ingredients} />

                    {/* Meals */}
                    <ProtectedRoute path="/admin/meals/:id" component={() => <EditMeals company={companyState.get} />} />

                    <ProtectedRoute path="/admin/meals" component={Meals} />

                    {/* Sides */}
                    <ProtectedRoute path="/admin/sides/:id" component={() => <EditSides company={companyState.get} />} />

                    <ProtectedRoute path="/admin/sides" component={Sides} />

                    {/* Customers */}
                    <ProtectedRoute path="/admin/customers/:id" component={EditCustomer} />

                    <ProtectedRoute path="/admin/customers" component={Customers} />

                    {/* Subscriptions */}
                    <ProtectedRoute path="/admin/subscriptions/:id" component={EditSubscription} />

                    <ProtectedRoute path="/admin/subscriptions" component={Subscriptions} />

                    <ProtectedRoute path="/admin/orders" component={Orders} />

                    <ProtectedRoute path="/admin/exports" component={Exports} />
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default withRouter(App);
