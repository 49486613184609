import React from 'react';
import { Text, View } from '@react-pdf/renderer';

function PacklistFooter(props) {
    const { styles } = props;
    return (
        <View style={styles.footerLayout}>
            <View style={styles.headerSection}>
                <Text
                    style={{
                        fontSize: '16px',
                        paddingBottom: '40px',
                        borderBottom: '2px solid #000',
                        width: '200px',
                    }}
                >
                    Signed By
                </Text>
            </View>
        </View>
    );
}

export default PacklistFooter;
