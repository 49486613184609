import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Table from '../components/Core/Table/Table';

function Orders(props) {
    const { getAccessTokenSilently } = useAuth0();

    const [getOrderData, setOrderData] = useState('');
    const [tableData, setTableData] = useState('');

    useEffect(() => {
        fetchOrderData();
    }, []);

    useEffect(() => {
        populateTableData();
    }, [getOrderData]);

    const fetchOrderData = async () => {
        const token = await getAccessTokenSilently();

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/orders/read`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('🚀 ~ file: Orders.js ~ line 21 ~ axios.post ~ response', response);
                setOrderData(response.data);
            })
            .catch((error) => console.log(error, 'error'));
    };

    const populateTableData = () => {
        const data = [];

        for (let i = 0; i < getOrderData.length; i += 1) {
            // Format the data for every order
            const { customer, subscription, name, paymentStatus, fulfillmentStatus, price, phone } = getOrderData[i];

            const row = {
                name: name,
                phone: phone,
                price: `€${price / 100}`,
                editCustomer: `/admin/customers/${customer}`,
                editSubscription: `/admin/subscriptions/${subscription}`,
                paymentStatus: paymentStatus,
                fulfillmentStatus: fulfillmentStatus,
            };
            data.push(row);
        }
        setTableData(data);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Price',
                accessor: 'price',
            },
            {
                Header: 'Customer',
                accessor: 'editCustomer',
            },
            {
                Header: 'Subscription',
                accessor: 'editSubscription',
            },
            {
                Header: 'Payment',
                accessor: 'paymentStatus',
            },
            {
                Header: 'Shipment',
                accessor: 'fulfillmentStatus',
            },
        ],
        []
    );

    return (
        <div className="w-full min-h-screen bg-gray-100 ">
            <div className=" w-full h-28 flex-none flex bg-white border-b">
                <div className="flex items-center p-8 px-12 justify-between w-full max-w-6xl mx-auto">
                    <h1 className="text-3xl font-semibold">Browse Orders</h1>
                    <div className="flex items-center"></div>
                </div>
            </div>
            <div className="p-12 max-w-6xl mx-auto">
                {tableData.length > 0 ? (
                    <Table columns={columns} data={tableData} name="Orders" />
                ) : (
                    <div className="grid gap-8">
                        <div className="animate-pulse bg-gray-200 w-full h-24 rounded-lg "></div>
                        <div className="animate-pulse bg-gray-200 w-full h-screen rounded-lg"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRouter(Orders);
