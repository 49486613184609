/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const OrderSearch = (props) => {
    const { name, handleSearchByName, handleSearchByPhone, handleSearchByPaid, handleSearchByShipped } = props;

    return (
        <div className="bg-white rounded-lg border mb-8">
            <div className="px-10 py-8 flex justify-between items-center">
                <h1 className="text-xl font-semibold text-gray-800">Filter {name}</h1>

                <div className="flex items-center justify-end">
                    <div className="w-28 ">
                        <div className="flex rounded-md">
                            <span className="inline-flex items-center pr-3 rounded-l-md text-sm text-brown-dark">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </span>
                            <input
                                type="text"
                                name="company-website"
                                onChange={handleSearchByName}
                                id="company-website"
                                className="flex-1 text-dark-brown block w-full px-3 py-2 sm:text-sm focus:ring-white focus:border-white bg-white border-white border-0 border-b"
                                placeholder="Name"
                            />
                        </div>
                    </div>

                    <div className="w-24 mr-2">
                        <div className="flex rounded-md">
                            <input
                                type="text"
                                name="company-website"
                                onChange={handleSearchByPhone}
                                id="company-website"
                                className="flex-1 text-dark-brown block w-full px-3 py-2 sm:text-sm bg-white focus:ring-white focus:border-white border-white border-0 border-b"
                                placeholder="Phone"
                            />
                        </div>
                    </div>

                    <div className="w-32 mr-2">
                        <div className="flex rounded-md">
                            <input
                                type="text"
                                name="company-website"
                                onChange={handleSearchByPaid}
                                id="company-website"
                                className="flex-1 text-dark-brown block w-full px-3 py-2 sm:text-sm bg-white focus:ring-white focus:border-white border-white border-0 border-b"
                                placeholder="Payment Status"
                            />
                        </div>
                    </div>

                    <div className="w-36">
                        <div className="flex rounded-md">
                            <input
                                type="text"
                                name="company-website"
                                onChange={handleSearchByShipped}
                                id="company-website"
                                className="flex-1 text-dark-brown block w-full px-3 py-2 sm:text-sm bg-white focus:ring-white focus:border-white border-white border-0 border-b"
                                placeholder="Shipment Status"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSearch;
