/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

const CustomerSearch = (props) => {
    const { name, handleSearchByName, handleSearchByPhone } = props;

    return (
        <div className="bg-white rounded-lg border mb-8">
            <div className="px-10 py-8 flex justify-between items-center">
                <h1 className="text-xl font-semibold text-gray-800">Filter {name}</h1>
                <div className="flex">
                    <div className="flex rounded-md">
                        <span className="inline-flex items-center pr-3 rounded-l-md text-sm text-brown-dark">
                            <SearchIcon className="w-5 h-5" />
                        </span>
                        <input
                            type="text"
                            name="company-website"
                            onChange={handleSearchByName}
                            id="company-website"
                            className="block flex-0 text-dark-brown px-3 py-2 sm:text-sm focus:ring-white focus:border-white bg-white border-white border-0 border-b"
                            placeholder="Search by name"
                        />
                    </div>
                    <div className="flex rounded-md">
                        <input
                            type="text"
                            name="company-website"
                            onChange={handleSearchByPhone}
                            id="company-website"
                            className="flex-1 text-dark-brown block w-full px-3 py-2 sm:text-sm bg-white focus:ring-white focus:border-white border-white border-0 border-b"
                            placeholder="Search by phone"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerSearch;
