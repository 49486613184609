/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
    ShoppingCartIcon,
    BeakerIcon,
    UserIcon,
    ClipboardIcon,
    RefreshIcon,
    ChartPieIcon,
    AdjustmentsIcon,
    ViewGridAddIcon,
    DocumentDownloadIcon,
} from '@heroicons/react/outline';

import AuthenticationButton from '../../authentication-button';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Menu(props) {
    const navigation = [
        {
            name: 'Meals',
            icon: ClipboardIcon,
            href: '/admin/meals',
        },
        {
            name: 'Ingredients',
            icon: BeakerIcon,
            href: '/admin/ingredients',
        },
        {
            name: 'Sides',
            icon: ViewGridAddIcon,
            href: '/admin/sides',
        },
        {
            name: 'Subs',
            icon: RefreshIcon,
            href: '/admin/subscriptions',
        },
        {
            name: 'Orders',
            icon: ShoppingCartIcon,
            href: '/admin/orders',
        },
        {
            name: 'Customers',
            icon: UserIcon,
            href: '/admin/customers',
        },
        {
            name: 'Exports',
            icon: DocumentDownloadIcon,
            href: '/admin/exports',
        },
        {
            name: 'Analytics',
            icon: ChartPieIcon,
            href: '/admin/analytics',
        },
        {
            name: 'Settings',
            icon: AdjustmentsIcon,
            href: '/admin/settings',
        },
    ];

    const { user } = useAuth0();
    let name = user ? user.name : ' ';
    name = name.split(' ')[0];

    return (
        <div className="side w-28 h-full  bg-gray-700 flex-none sticky top-0  h-screen">
            <div className="w-28 h-28 flex justify-center items-center bg-gray-800 flex-none border-b border-gray-800">
                <img src="/Icon.png" className="h-12" alt="" />
            </div>

            <div className="grid pt-12 gap-8">
                {navigation.map((item) => (
                    <NavLink to={item.href} className="flex-row justify-center items-center">
                        <item.icon className="w-6 h-6 text-white mx-auto mb-1" />
                        <p className="font-semibold text-center text-sm text-white">{item.name}</p>
                    </NavLink>
                ))}
                <p className="font-semibold text-center text-sm text-white">
                    Welcome
                    <br />
                    {name}
                    <br />
                    <br />
                    <AuthenticationButton />
                </p>
            </div>
        </div>
    );
}

export default Menu;
