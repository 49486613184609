import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import TextInput from '../../components/Form/TextInput';
import FormSection from '../../components/Form/FormSection';

function EditCustomer(props) {
    const [user, setUser] = useState(false);

    const fetchUser = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/read`, {
                query: { _id: props.match.params.id },
            })
            .then((response) => {
                setUser(response.data[0]);
            });
    };

    const saveUser = (values) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/update`, {
                query: { _id: props.match.params.id },
                body: { contactInformation: values },
            })
            .then(() => toast.success('User Saved'));
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <div className=" min-h-screen w-full bg-gray-100">
            <Toaster position="bottom-center" />

            <Formik
                enableReinitialize
                initialValues={{
                    firstName: user ? user.contactInformation.firstName : '',
                    lastName: user ? user.contactInformation.lastName : '',
                    fullName: user ? user.contactInformation.fullName : '',
                    phone: user ? user.contactInformation.phone : '',
                }}
                validationSchema={Yup.object({
                    firstName: Yup.string().max(25, 'Must be 25 characters or less').required('First name is required'),
                    lastName: Yup.string().max(25, 'Must be 25 characters or less').required('Last name is required'),
                    fullName: Yup.string().max(25, 'Must be 25 characters or less').required('Full name is required'),
                    phone: Yup.string().max(25, 'Must be 25 characters or less').required('Phone number is required'),
                })}
                onSubmit={(values) => {
                    saveUser(values);
                }}
            >
                <Form className="flex flex-col w-full min-h-screen">
                    {/* Header */}
                    <div className=" w-full h-28 flex-none flex bg-white">
                        <div className="flex items-center p-8 px-12 justify-between w-full">
                            <h1 className="text-3xl font-semibold">Edit Customer</h1>
                            <div className="flex items-center">
                                <Link to="/admin/Customers" className="px-8 py-3 text-gray-800 font-semibold">
                                    Back to Customers
                                </Link>
                                <button type="submit" id="save" className="button-primary-md">
                                    Save Customer
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row main-area p-12 flex-1 bg-gray-100 border-t min-h-full">
                        <div className="h-full grid grid-cols-3 gap-12 max-w-6xl">
                            <div className="col-span-2 grid gap-12">
                                <FormSection title="Information">
                                    <TextInput label="First Name" name="firstName" type="text" />
                                    <TextInput label="Second Name" name="lastName" type="text" />
                                    <TextInput label="Full Name" name="fullName" type="text" />
                                    <TextInput label="Phone ( International Format )" name="phone" type="text" />
                                </FormSection>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default withRouter(EditCustomer);
