import React from 'react';
import { Text, View } from '@react-pdf/renderer';

function TypeRows(props) {
    const {
        plan: {
            config,
            meals: { selected },
        },
        part,
        type,
        styles,
    } = props;

    if (config[type] > 0) {
        const amountOfTypePerDay = parseInt(config[type]);
        const amountOfTypePerWeek = amountOfTypePerDay * config.daysOfFood;
        const half = Math.round(amountOfTypePerWeek / 2);
        let startSliceAt = 0;
        let finishSliceAt = 0;

        if (part === 1) {
            finishSliceAt = half;
        } else if (part === 2) {
            startSliceAt = half;
            finishSliceAt = amountOfTypePerWeek;
        }

        const selectedHalf = selected[type].slice(startSliceAt, finishSliceAt);

        const Rows = selectedHalf.map((key, i) => (
            <View style={styles.tableRow}>
                <View style={styles.tableData}>
                    <Text style={styles.basicContent}>{key[1]}x </Text>
                    <br />
                </View>
                <View style={styles.mealCell}>
                    <Text style={styles.basicContent}>{key.name}</Text>
                    <br />
                </View>
                <View style={styles.tableData}>
                    <Text style={styles.checkboxCell} />
                    <br />
                </View>
            </View>
        ));

        return (
            <View style={{ padding: '25px' }}>
                <Text style={{ fontSize: '20px', marginBottom: '9px', textTransform: 'capitalize' }}>{type}</Text>
                {Rows}
            </View>
        );
    }
    return <View />;
}

export default TypeRows;
