import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import ReactJson from 'react-json-view';

function EditSubscription(props) {
    const [user, setUser] = useState(false);
    const [plan, setPlan] = useState(false);

    const fetchUser = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/read`, {
                query: { _id: props.match.params.id },
            })
            .then((response) => {
                setUser(response.data[0]);
            });
    };

    const fetchPlan = () => {
        if (user.subscription) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/plans/read`, {
                    query: { _id: user.subscription.planId },
                })
                .then((response) => {
                    setPlan(response.data[0]);
                });
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        fetchPlan();
    }, [user]);

    return (
        <div className=" min-h-screen w-full">
            <div className="bg-white rounded-lg">
                {/* Header */}
                <div className=" w-full h-28 flex-none flex bg-white">
                    <div className="flex items-center p-8 px-12 justify-between w-full">
                        <h1 className="text-3xl font-semibold">View Subscription</h1>
                        <div className="flex items-center">
                            <Link to="/admin/Subscriptions" className="px-8 py-3 text-gray-800 font-semibold">
                                Back to Subscriptions
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Body */}
                <div className="flex flex-row main-area p-12 flex-1 bg-gray-100 border-t">
                    <div className="grid grid-cols-2">
                        <div className="flex flex-col">
                            <h3 className="font-semibold text-brown-dark text-xl">Subscription Data</h3>
                            <p className="text-sm pb-8 text-gray-800">
                                Raw data, until we find out what information is needed here
                            </p>
                            <ReactJson src={user.subscription} />
                        </div>

                        <div className="flex flex-col">
                            <h3 className="font-semibold text-brown-dark text-xl">Plan Data</h3>
                            <p className="text-sm pb-8 text-gray-800">
                                Raw data, until we find out what information is needed here
                            </p>
                            <div className="my-6">{plan ? <ReactJson src={plan} /> : ''}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(EditSubscription);
