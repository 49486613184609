import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';

import ThreeStats from '../../components/Core/Data/ThreeStats';
import Table from '../../components/Core/Table/Table';

function Subscriptions(props) {
    const [getUserData, setUserData] = useState('');
    const [tableData, setTableData] = useState('');
    const [statData, setStatData] = useState([
        { name: 'Active Subscriptions', stat: '0' },
        { name: 'Paused Subscriptions', stat: '0' },
        { name: 'Cancelled Subscriptions', stat: '0' },
    ]);

    useEffect(() => {
        fetchSubscriptionData();
    }, []);

    useEffect(() => {
        populateTableData();
        populateStats();
    }, [getUserData]);

    const fetchSubscriptionData = async () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/read`, {
                jsonFilter: { 'subscription.status': { $ne: '' } },
            })
            .then((response) => setUserData(response.data));
    };

    const populateStats = () => {
        if (!getUserData) {
            return;
        }

        // Get the active subscriptions
        const activeSubs = getUserData.filter((el) => el.subscription.status === 'active');

        // Get the paused subscriptions
        const pausedSubs = getUserData.filter((el) => el.subscription.status === 'paused');

        // Get the cancelled subscriptions
        const cancelledSubs = getUserData.filter((el) => el.subscription.status === 'cancelled');

        setStatData([
            { name: 'Active Subscriptions', stat: activeSubs.length },
            { name: 'Paused Subscriptions', stat: pausedSubs.length },
            { name: 'Cancelled Subscriptions', stat: cancelledSubs.length },
        ]);
    };

    const populateTableData = () => {
        const data = [];

        for (let i = 0; i < getUserData.length; i += 1) {
            const {
                _id,
                contactInformation: { fullName, phone },
                subscription: { price, status },
            } = getUserData[i];

            const row = {
                name: fullName,
                phone: phone,
                status: status,
                price: `€${price}`,
                link: `/admin/subscriptions/${_id}`,
            };
            data.push(row);
        }
        setTableData(data);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Price',
                accessor: 'price',
            },
            {
                Header: '',
                accessor: 'link',
                className: 'link',
            },
        ],
        []
    );

    return (
        <div className="w-full min-h-screen bg-gray-100 ">
            <div className=" w-full h-28 flex-none flex bg-white border-b">
                <div className="flex items-center p-8 px-12 justify-between w-full max-w-6xl mx-auto">
                    <h1 className="text-3xl font-semibold">Browse Subscriptions</h1>
                </div>
            </div>
            <div className="p-12 max-w-6xl mx-auto">
                <ThreeStats stats={statData} />

                {tableData.length > 0 ? (
                    <Table columns={columns} data={tableData} name="Subscriptions" />
                ) : (
                    <div className="grid gap-8">
                        <div className="animate-pulse bg-gray-200 w-full h-24 rounded-lg "></div>
                        <div className="animate-pulse bg-gray-200 w-full h-screen rounded-lg"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRouter(Subscriptions);
